import React, { createContext, useContext, useState } from "react";

type TopBarContextState = {
  visibleState: boolean;
  update: (state: boolean) => void;
};

const initialTopBarContextState: TopBarContextState = {
  visibleState: true,
  update: () => null,
};

const TopBarContext = createContext<TopBarContextState>(
  initialTopBarContextState
);

export const useTopBarState = (): TopBarContextState =>
  useContext(TopBarContext);

export const TopBarStateProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }): React.ReactElement => {
  const [topBarVisibleState, setTopBarVisibleState] = useState(
    initialTopBarContextState.visibleState
  );

  return (
    <TopBarContext.Provider
      value={{
        visibleState: topBarVisibleState,
        update: setTopBarVisibleState,
      }}
    >
      {children}
    </TopBarContext.Provider>
  );
};
