import { SonataResponse } from "../types/SonataResponse";
import { AxiosResponse } from "axios";
import { Slug, SlugChild } from "../types/Slug";

export const sonataDataGetter = <T>(
  response: AxiosResponse<SonataResponse<T>>
): T => {
  const { data } = response;
  return data["hydra:member"];
};

export const getSlugsFlatArray = (slugs: Array<Slug>): Array<SlugChild> => {
  let slugsArray: Array<SlugChild> = [];
  slugs.forEach((slug) => {
    if (slug.childs.length > 0) {
      slugsArray = [
        ...slugsArray,
        ...slug.childs,
        { id: slug.id, name: slug.name },
      ];
    } else {
      slugsArray.push({ id: slug.id, name: slug.name });
    }
  });

  return slugsArray;
};
