import Link from "next/link";
import React from "react";
import { MenuRoute } from "../../../types/MenuRoute";

type LinkRenderProps = {
  item: MenuRoute;
  className: string;
  lang: string;
};

const LinkRender: React.FunctionComponent<LinkRenderProps> = ({
  item,
  className,
  lang,
}): React.ReactElement => {
  if (/(http(s?)):\/\//gi.test(item.urls)) {
    return (
      <a
        href={item.urls}
        target="_blank"
        rel="noopener noreferrer nofollow"
        className={className}
      >
        {item.names}
      </a>
    );
  } else {
    return (
      <Link href={`/${lang}${item.urls}`} className={className}>
        {item.names}
      </Link>
    );
  }
};

export default LinkRender;
