import constants from "../../../../utils/constants";

export const getLogoSrcForCurrentLang = (lang: string): string => {
  let logoLang = lang;

  const secondaryLogoSameAsPrimary =
    process.env.NEXT_PUBLIC_SECONDARY_LOGO_SAME_AS_PRIMARY === "true";

  const primaryLanguage = process.env.NEXT_PUBLIC_PRIMARY_LANG;

  if (
    secondaryLogoSameAsPrimary &&
    primaryLanguage &&
    primaryLanguage !== lang
  ) {
    logoLang = primaryLanguage;
  }

  switch (logoLang) {
    case constants.LANGUAGE.PL:
      return "/blocks/MainHeader/logoCLpl.svg";

    case constants.LANGUAGE.RO:
      return "/blocks/MainHeader/logoCLro.svg";

    case constants.LANGUAGE.AT:
      return "/blocks/MainHeader/logoCLat.svg";

    default:
      return "/blocks/MainHeader/logoCLen.svg";
  }
};

export const getDarkLogoSrcForCurrentLang = (lang: string): string => {
  let logoLang = lang;

  const secondaryLogoSameAsPrimary =
    process.env.NEXT_PUBLIC_SECONDARY_LOGO_SAME_AS_PRIMARY === "true";

  const primaryLanguage = process.env.NEXT_PUBLIC_PRIMARY_LANG;

  if (
    secondaryLogoSameAsPrimary &&
    primaryLanguage &&
    primaryLanguage !== lang
  ) {
    logoLang = primaryLanguage;
  }

  switch (logoLang) {
    case constants.LANGUAGE.PL:
      return "/blocks/MainHeader/logoCLplDark.svg";

    case constants.LANGUAGE.RO:
      return "/blocks/MainHeader/logoCLroDark.svg";

    case constants.LANGUAGE.AT:
      return "/blocks/MainHeader/logoCLatDark.svg";
    default:
      return "/blocks/MainHeader/logoCLenDark.svg";
  }
};
