import React from "react";
import Media from "react-media";
import constants from "../../../utils/constants";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import LinkRender from "../LinkRender/LinkRender";
import { MenuRoute } from "../../../types/MenuRoute";
import { isMobile } from "mobile-device-detect";
import { SocialMedia } from "../../../types/CompanyData";
import Icon from "../Icon";

type FooterProps = {
  routes: MenuRoute[];
  lang: string;
  socialMedia: SocialMedia[];
};

const Footer: React.FunctionComponent<FooterProps> = ({
  routes,
  lang,
  socialMedia,
}): React.ReactElement => {
  const { t } = useTranslation();
  const parsedRoutes = routes.filter(
    (route: MenuRoute) =>
      route.positions.includes(constants.MENU_POSITIONS.BOTTOM) && route.names
  );
  const routesToSplit = parsedRoutes;
  const halfWayThough = Math.floor(routesToSplit.length / 2);

  const routesLeft = routesToSplit.slice(0, halfWayThough);
  const routesRight = routesToSplit.slice(halfWayThough, routesToSplit.length);
  const currentYear = new Date().getFullYear();

  const NEXT_PUBLIC_INSTAGRAM_URL =
    process.env.NEXT_PUBLIC_INSTAGRAM_URL ?? null;
  const NEXT_PUBLIC_LINKEDIN_URL = process.env.NEXT_PUBLIC_LINKEDIN_URL ?? null;
  const NEXT_PUBLIC_FACEBOOK_URL = process.env.NEXT_PUBLIC_FACEBOOK_URL ?? null;
  const NEXT_PUBLIC_TWITTER_URL = process.env.NEXT_PUBLIC_TWITTER_URL ?? null;
  const NEXT_PUBLIC_YOUTUBE_URL = process.env.NEXT_PUBLIC_YOUTUBE_URL ?? null;

  const socialMediaArrayIsEmpty = socialMedia.length === 0;

  const renderSocialMediaIcons = (): React.ReactElement => (
    <div className="iconsContainer">
      {socialMediaArrayIsEmpty ? (
        <>
          {NEXT_PUBLIC_INSTAGRAM_URL && (
            <a
              href={NEXT_PUBLIC_INSTAGRAM_URL}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="icon"
            >
              <Icon icon="INSTAGRAM" width="32px" color="white-icon" />
            </a>
          )}
          {NEXT_PUBLIC_LINKEDIN_URL && (
            <a
              href={NEXT_PUBLIC_LINKEDIN_URL}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="icon"
            >
              <Icon icon="LINKEDIN" width="32px" color="white-icon" />
            </a>
          )}
          {NEXT_PUBLIC_FACEBOOK_URL && (
            <a
              href={NEXT_PUBLIC_FACEBOOK_URL}
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="icon"
            >
              <Icon icon="FACEBOOK" width="32px" color="white-icon" />
            </a>
          )}
          {NEXT_PUBLIC_TWITTER_URL && (
            <a
              href={NEXT_PUBLIC_TWITTER_URL}
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="icon"
            >
              <Icon icon="TWITTER" width="32px" color="white-icon" />
            </a>
          )}
          {NEXT_PUBLIC_YOUTUBE_URL && (
            <a
              href={NEXT_PUBLIC_YOUTUBE_URL}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="icon"
            >
              <Icon icon="YT" width="32px" color="white-icon" />
            </a>
          )}
        </>
      ) : (
        socialMedia.map((sm) => (
          <a
            key={sm.link}
            href={sm.link}
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="icon"
          >
            <img
              className="socialMediaIcon"
              alt="social media icon"
              src={process.env.NEXT_PUBLIC_IMG_URL + sm.icon.publicUrls}
            />
          </a>
        ))
      )}
    </div>
  );

  return (
    <div>
      <Media query="(min-width: 768px)" defaultMatches>
        <div className="footer">
          <div className="topContainer">
            {parsedRoutes.map((item) => (
              <LinkRender
                className="topContainer__text"
                lang={lang}
                item={item}
                key={item.id}
              />
            ))}
          </div>
          <div className="bottomContainer">
            {renderSocialMediaIcons()}

            <a
              href="https://coderslab.pl"
              target="_blank"
              rel="noopener noreferrer"
              className="bottomContainer__text bottomContainer__text--first"
            >
              {t("footer-copy")}
              {currentYear}
            </a>
            <Link
              passHref
              href={`/${lang}${t("routes-rules")}`}
              className="bottomContainer__text"
            >
              {t("footer-rules")}
            </Link>
            <Link
              passHref
              href={`/${lang}${t("routes-privacy")}`}
              className="bottomContainer__text"
            >
              {t("footer-policy")}
            </Link>
          </div>
        </div>
      </Media>

      <Media query="(max-width: 767px)" defaultMatches={isMobile}>
        <div className="footer">
          <div className="topContainer">
            <div className="topContainer__column topContainer__column--left">
              {routesLeft.map((item) => (
                <LinkRender
                  className="topContainer__text"
                  lang={lang}
                  item={item}
                  key={item.id}
                />
              ))}
              <Link
                passHref
                href={`/${lang}${t("routes-privacy")}`}
                className="topContainer__text"
              >
                {t("footer-policy")}
              </Link>
            </div>
            <div className="topContainer__column topContainer__column--right">
              {routesRight.map((item) => (
                <LinkRender
                  className="topContainer__text"
                  lang={lang}
                  item={item}
                  key={item.id}
                />
              ))}
              <Link
                passHref
                href={`/${lang}${t("routes-rules")}`}
                className="topContainer__text"
              >
                {t("footer-rules")}
              </Link>
            </div>

            {renderSocialMediaIcons()}
          </div>
          <div className="bottomContainer">
            <span className="bottomContainer__text bottomContainer__text--first">
              {t("footer-copy")}
            </span>
          </div>
        </div>
      </Media>
    </div>
  );
};

export default Footer;
