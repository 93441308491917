import React, { useEffect, useRef, useState } from "react";
import { contactIcon } from "../assets/icons";
import DesktopMenuItem from "./DesktopMenuItem";
import { getLogoSrcForCurrentLang } from "../utils/LogoProvider";
import { MenuRoute } from "../../../../types/MenuRoute";
import { useTranslation } from "next-i18next";
import { cartIcon, chevronRight } from "../../../../utils/icons";

type DesktopMenuProps = {
  menuItems: Array<MenuRoute>;
  lang: string;
  handleLanguageSwitch: () => void;
};

const DesktopMenu: React.FunctionComponent<DesktopMenuProps> = ({
  menuItems,
  lang,
  handleLanguageSwitch,
}): React.ReactElement => {
  const ref = useRef<HTMLUListElement | null>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    {
      setShowLeftArrow(shouldLeftArrowShow());
      setShowRightArrow(shouldRightArrowShow());
    }
  });

  const sideScroll = (direction: "left" | "right"): void => {
    const { current } = ref;
    if (current) {
      let scrollAmount = 0;
      const step = 10;
      const distance = 100;
      const speed = 25;
      const slideTimer = setInterval(() => {
        if (direction == "left") {
          current.scrollLeft -= step;
        } else {
          current.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
        setShowLeftArrow(shouldLeftArrowShow());
        setShowRightArrow(shouldRightArrowShow());
      }, speed);
    }
  };

  const shouldLeftArrowShow = (): boolean => {
    const { current } = ref;

    return current
      ? current.scrollLeft !== 0 && current.scrollWidth > current.clientWidth
      : true;
  };

  const shouldRightArrowShow = (): boolean => {
    const { current } = ref;
    return current
      ? current.scrollWidth > current.clientWidth &&
          current.scrollLeft + current.clientWidth !== current.scrollWidth
      : true;
  };

  return (
    <div className="main-header__container">
      <div className="main-header__logoSection">
        <a href={`/${lang}`}>
          <img
            className="mainLogo"
            src={getLogoSrcForCurrentLang(lang)}
            alt="CodersLab"
          />
        </a>
      </div>
      <div className="main-header__navigationContainer">
        <nav className="main-header__navigation">
          {showLeftArrow && (
            <div
              className="main-header__navigation--leftArrow"
              onClick={() => sideScroll("left")}
            >
              {chevronRight}
            </div>
          )}
          {showRightArrow && (
            <div
              className="main-header__navigation--rightArrow"
              onClick={() => sideScroll("right")}
            >
              {chevronRight}
            </div>
          )}
          <ul className="navigationMenu" ref={ref}>
            {menuItems.map((item) => (
              <DesktopMenuItem
                key={item.id}
                item={item}
                lang={lang}
                containerScrollLeft={ref?.current?.scrollLeft}
              />
            ))}
          </ul>
        </nav>
      </div>
      <div className="main-header__right-section">
        {process.env.NEXT_PUBLIC_SECONDARY_LANG && (
          <div
            className="main-header__languages"
            onClick={handleLanguageSwitch}
          >
            <div>
              <span
                className={
                  lang === process.env.NEXT_PUBLIC_PRIMARY_LANG
                    ? "main-header__languages--active"
                    : ""
                }
              >
                {process.env.NEXT_PUBLIC_PRIMARY_LANG}
              </span>
              &nbsp;/&nbsp;
              <span
                className={
                  lang === process.env.NEXT_PUBLIC_SECONDARY_LANG
                    ? "main-header__languages--active"
                    : ""
                }
              >
                {process.env.NEXT_PUBLIC_SECONDARY_LANG}
              </span>
            </div>
          </div>
        )}
        <a
          href={`/${lang}${t("routes-contact")}`}
          className="modern-button modern-button-primary modern-button-small contact-button"
        >
          {contactIcon} {t("headers-contact")}
        </a>
        {process.env.NEXT_PUBLIC_SHOP_URL_BASE && (
          <a
            className="modern-button modern-button-primary modern-button-small shop-button"
            href={process.env.NEXT_PUBLIC_SHOP_URL_BASE}
            target="_blank"
            rel="noopener noreferrer"
          >
            {cartIcon} {t("header-shop")}
          </a>
        )}
      </div>
    </div>
  );
};

export default DesktopMenu;
