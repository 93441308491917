import React, { useEffect, useState } from "react";
import BasicButton from "../../components/BasicButton";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { getCookie, setCookies } from "cookies-next";

const COOKIE_AGREEMENT_KEY = "cookiesAgreement";

const CookiesConfirmation: React.FunctionComponent = (): React.ReactElement => {
  const { t } = useTranslation();
  const { locale: lang } = useRouter();
  const [cookiesAgreement, setCookiesAgreement] = useState("true");

  useEffect(() => {
    const cookie = getCookie(COOKIE_AGREEMENT_KEY);
    setCookiesAgreement(String(!!cookie));
  }, []);

  const handleAgreement = (): void => {
    setCookies(COOKIE_AGREEMENT_KEY, "true", {
      path: "/",
      maxAge: 31536000,
    });
    setCookiesAgreement("true");
  };

  return cookiesAgreement === "false" ? (
    <div className="cookiesConfirmation">
      <div className=" cookiesConfirmation__container">
        <p>
          <span>{t("cookies")}</span>{" "}
          <Link href={`/${lang}/${t("routes-privacy")}`}>
            {t("cookies-link")}
          </Link>
        </p>
        <BasicButton
          onClick={handleAgreement}
          className="cookiesConfirmation__btn"
        >
          {t("cookies-confirm")}
        </BasicButton>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CookiesConfirmation;
