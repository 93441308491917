import * as React from "react";
import Drawer from "rc-drawer";
import "rc-drawer/assets/index.css";
import Icon from "../../../components/Icon";
import { getDarkLogoSrcForCurrentLang } from "../utils/LogoProvider";
import PictureProvider from "../../../components/Picture";
import { isFullUrl } from "../../../../utils/validators";
import { MenuRoute, MenuRouteChild } from "../../../../types/MenuRoute";
import { useState } from "react";
import { useTranslation } from "next-i18next";

type MobileMenuProps = {
  menuItems: Array<MenuRoute>;
  mobileBarItems: Array<MenuRoute>;
  lang: string;
  handleLanguageSwitch: () => void;
};

const MobileMenu: React.FunctionComponent<MobileMenuProps> = ({
  lang,
  mobileBarItems,
  menuItems,
  handleLanguageSwitch,
}) => {
  const { t } = useTranslation();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [secondLevelChildId, setSecondLevelChildId] = useState("");

  const handleOpenDrawer = (): void => setOpenDrawer(true);

  const handleCloseDrawer = (): void => {
    setOpenDrawer(false);
    setSecondLevelChildId("");
  };

  const handleOpenSecondLevel = (childId: string): void =>
    setSecondLevelChildId(childId);

  const handleCloseSecondLevel = (): void => setSecondLevelChildId("");

  const renderItem = (
    names: string,
    url: string | null,
    target: React.HTMLAttributeAnchorTarget,
    id: string,
    children: Array<MenuRouteChild>,
    seoTitle: string | null,
    onClickHandler: (id: string) => void
  ): React.ReactElement => {
    return children.length ? (
      <div
        title={seoTitle ? seoTitle : undefined}
        className="mobileMenu__item mobileMenu__firstLevelSubItem"
        onClick={() => onClickHandler(id)}
      >
        <span>{names}</span>
        <span>
          <Icon color="dark-grey" icon="NEXT" width="16px" height="16px" />
        </span>
      </div>
    ) : (
      <div className="mobileMenu__item mobileMenu__firstLevelSubItem">
        {url ? (
          isFullUrl(url) ? (
            <a
              href={url}
              title={seoTitle ? seoTitle : undefined}
              target={target}
              rel="noopener noreferrer"
            >
              {names}
            </a>
          ) : (
            <a
              href={`/${lang}${url}`}
              title={seoTitle ? seoTitle : undefined}
              target={target}
            >
              {names}
            </a>
          )
        ) : (
          <span title={seoTitle ? seoTitle : undefined}>{names}</span>
        )}
      </div>
    );
  };

  const renderFirstLevelItem = (
    name: string,
    url: string,
    target: React.HTMLAttributeAnchorTarget,
    seoTitle: string | null
  ): React.ReactElement => {
    return (
      <div className="mobileMenu__item">
        {url ? (
          isFullUrl(url) ? (
            <a
              href={url}
              title={seoTitle ? seoTitle : undefined}
              target={target}
              rel="noopener noreferrer"
            >
              {name}
            </a>
          ) : (
            <a
              href={`/${lang}${url}`}
              title={seoTitle ? seoTitle : undefined}
              target={target}
            >
              {name}
            </a>
          )
        ) : (
          <span title={seoTitle ? seoTitle : undefined}>{name}</span>
        )}
      </div>
    );
  };

  const renderSecondLevel = (item: MenuRouteChild): React.ReactElement => {
    const showWithIcon = item.children.find(
      (i) => i.iconPicture && i.iconPicture.publicUrl
    );
    return (
      <ul className="mobileMenu__secondLevel">
        {item.children.map((lastLevelItem: MenuRouteChild) => {
          const linkContetnt = (
            <>
              {showWithIcon && (
                <div className="navigationSubMenu__secondLevelItemImg">
                  {lastLevelItem.iconPicture && (
                    <PictureProvider data={lastLevelItem.iconPicture} />
                  )}
                </div>
              )}
              <div
                className={
                  showWithIcon
                    ? "navigationSubMenu__secondLevelItemWithImg"
                    : ""
                }
              >
                <div> {lastLevelItem.names}</div>
                <div className="navigationSubMenu__additionalTextLabel">
                  {lastLevelItem.additionalText && (
                    <span className="navigationSubMenu__additionalTextLabel">
                      {lastLevelItem.additionalText}
                    </span>
                  )}
                  {lastLevelItem.additionalTextWithBg && (
                    <span
                      style={{
                        color: lastLevelItem.additionalTextWbgColor
                          ? lastLevelItem.additionalTextWbgColor
                          : undefined,
                        padding: "1px 10px",
                        backgroundColor:
                          lastLevelItem.additionalTextWbgBackground
                            ? lastLevelItem.additionalTextWbgBackground
                            : undefined,
                      }}
                    >
                      {lastLevelItem.additionalTextWithBg}
                    </span>
                  )}
                </div>
              </div>
            </>
          );

          return (
            <li
              className={
                lastLevelItem.menuHeader
                  ? "navigationSubMenu__secondLevelItem navigationSubMenu__secondLevelItem--bold navigationSubMenu__secondLevelItem--mobile"
                  : "navigationSubMenu__secondLevelItem"
              }
              key={lastLevelItem.id}
            >
              {lastLevelItem.urls ? (
                isFullUrl(lastLevelItem.urls) ? (
                  <a
                    title={
                      lastLevelItem.seoTitle
                        ? lastLevelItem.seoTitle
                        : undefined
                    }
                    href={lastLevelItem.urls}
                    target={lastLevelItem.target}
                    rel="noopener noreferrer"
                  >
                    {linkContetnt}
                  </a>
                ) : (
                  <a
                    title={
                      lastLevelItem.seoTitle
                        ? lastLevelItem.seoTitle
                        : undefined
                    }
                    href={`/${lang}${lastLevelItem.urls}`}
                    target={lastLevelItem.target}
                  >
                    {linkContetnt}
                  </a>
                )
              ) : (
                <span
                  title={
                    lastLevelItem.seoTitle ? lastLevelItem.seoTitle : undefined
                  }
                >
                  {showWithIcon && (
                    <div className="navigationSubMenu__secondLevelItemImg">
                      {lastLevelItem.iconPicture && (
                        <PictureProvider data={lastLevelItem.iconPicture} />
                      )}
                    </div>
                  )}
                  <div
                    className={
                      showWithIcon
                        ? "navigationSubMenu__secondLevelItemWithImg"
                        : ""
                    }
                  >
                    <div> {lastLevelItem.names}</div>
                    <div className="navigationSubMenu__additionalTextLabel">
                      {lastLevelItem.additionalText && (
                        <span className="navigationSubMenu__additionalTextLabel">
                          {lastLevelItem.additionalText}
                        </span>
                      )}
                      {lastLevelItem.additionalTextWithBg && (
                        <span
                          style={{
                            color: lastLevelItem.additionalTextWbgColor
                              ? lastLevelItem.additionalTextWbgColor
                              : undefined,
                            padding: "1px 10px",
                            backgroundColor:
                              lastLevelItem.additionalTextWbgBackground
                                ? lastLevelItem.additionalTextWbgBackground
                                : undefined,
                          }}
                        >
                          {lastLevelItem.additionalTextWithBg}
                        </span>
                      )}
                    </div>
                  </div>
                </span>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const logoSection = (
    <div className="mobileMenu__headerSection">
      <img src={getDarkLogoSrcForCurrentLang(lang)} alt="CodersLab" />
      {openDrawer && (
        <button className="closeMenuButton" onClick={handleCloseDrawer}>
          +
        </button>
      )}
    </div>
  );

  return (
    <div>
      <style>
        {`
          @media only screen and (max-width: 767px) {
            iframe#usercom-launcher-dot-frame[name],
            #usercom-greeting-wrapper-frame {
              left: calc(100% / ${
                (mobileBarItems.length + 2) * 2
              } - 20px) !important;
            }
          }

          @media only screen and (min-width: 551px) and (max-width: 767px) {
            #usercom-board-frame {
              left: calc(100% / ${
                (mobileBarItems.length + 2) * 2
              } - 20px) !important;
            }
          }
         `}
      </style>
      <Drawer
        width="100vw"
        handler={false}
        className="mainDrawer"
        open={openDrawer}
        onClose={handleCloseDrawer}
        placement="left"
        level={null}
      >
        <div className="mobileMenu">
          {logoSection}
          {menuItems.map((menuItem) => (
            <div key={menuItem.id} className="mobileMenu__firstLevel">
              {renderFirstLevelItem(
                menuItem.names,
                menuItem.urls,
                menuItem.target,
                menuItem.seoTitle
              )}

              <div
                className={
                  menuItem.children.length ? "mobileMenu__itemsGroup" : ""
                }
              >
                {menuItem.children.map((childItem: MenuRouteChild) => (
                  <div key={childItem.id}>
                    {renderItem(
                      childItem.names,
                      childItem.urls,
                      childItem.target,
                      childItem.id,
                      childItem.children,
                      childItem.seoTitle,
                      handleOpenSecondLevel
                    )}

                    <Drawer
                      width="100vw"
                      handler={false}
                      open={secondLevelChildId === childItem.id}
                      onClose={handleCloseSecondLevel}
                      level={[".drawer1", ".drawer2"]}
                      placement="left"
                    >
                      {logoSection}

                      <div
                        className="mobileMenu__itemHeader"
                        onClick={handleCloseSecondLevel}
                      >
                        <Icon
                          className="rotateArrow"
                          color="dark-grey"
                          icon="NEXT"
                          width="16px"
                          height="16px"
                        />
                        {childItem.names}
                      </div>

                      {renderSecondLevel(childItem)}
                    </Drawer>
                  </div>
                ))}
              </div>
            </div>
          ))}
          {process.env.NEXT_PUBLIC_SECONDARY_LANG && (
            <div
              className="mobileMenu__languages"
              onClick={handleLanguageSwitch}
            >
              <div>
                <span
                  className={
                    lang === process.env.NEXT_PUBLIC_PRIMARY_LANG
                      ? "mobileMenu__languages--active"
                      : ""
                  }
                >
                  {process.env.NEXT_PUBLIC_PRIMARY_LANG}
                </span>
                &nbsp;/&nbsp;
                <span
                  className={
                    lang === process.env.NEXT_PUBLIC_SECONDARY_LANG
                      ? "mobileMenu__languages--active"
                      : ""
                  }
                >
                  {process.env.NEXT_PUBLIC_SECONDARY_LANG}
                </span>
              </div>
            </div>
          )}
        </div>
      </Drawer>
      <div className="bottomMobileMenu">
        {process.env.NEXT_PUBLIC_UE_KEY && (
          <div className="bottomMobileMenu__item" />
        )}
        {mobileBarItems.map(({ names, seoTitle = "", urls, iconPicture }) => {
          return isFullUrl(urls) ? (
            <div className="bottomMobileMenu__item" key={names}>
              <a target="_blank" rel="noopener noreferrer" href={urls}>
                {iconPicture ? (
                  <PictureProvider
                    data={iconPicture}
                    className="bottomMobileMenu__icon--white"
                  />
                ) : (
                  <img
                    className="bottomMobileMenu__icon--white"
                    src="/blocks/MainHeader/coursesList.svg"
                    alt={names}
                  />
                )}
                {names}
              </a>
            </div>
          ) : (
            <div
              key={urls}
              className="bottomMobileMenu__item"
              title={seoTitle ? seoTitle : undefined}
            >
              <a href={`/${lang}${urls}`}>
                {iconPicture ? (
                  <PictureProvider
                    data={iconPicture}
                    className="bottomMobileMenu__icon--white"
                  />
                ) : (
                  <img
                    className="bottomMobileMenu__icon--white"
                    src="/blocks/MainHeader/coursesList.svg"
                    alt={names}
                  />
                )}
                {names}
              </a>
            </div>
          );
        })}

        <div className="bottomMobileMenu__item">
          <div
            onClick={handleOpenDrawer}
            className={
              openDrawer ? "open-menu-button active" : "open-menu-button"
            }
          >
            <img
              className="bottomMobileMenu__icon--white"
              src="/blocks/MainHeader/menu.svg"
              alt="menu icon"
            />
            {t("Menu")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
