export const isFullUrl = (url: string): boolean =>
  url.startsWith("http://") ||
  url.startsWith("https://") ||
  url.startsWith("www.");

export const isAnchor = (url: string): boolean => url.startsWith("#");

export function phoneValidator(values: unknown, value: string) {
  const PHONE_NUMBER_MIN_LENGTH = 9;
  if (!value || value.length < PHONE_NUMBER_MIN_LENGTH) {
    return false;
  }
  const errorPatterns = ["123123123", "123456789"];
  const hasRightChars =
    /^\+?([0-9]{2,3})?[- ]?([0-9]{2,3})\)?[- ]?([0-9]{2,3})\)?[- ]?([0-9]{3,})$/;
  if (!hasRightChars.test(value)) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const pureNumber = [...value].filter(
    (char) => char !== "+" && char !== "-" && char !== " "
  );
  const sameNumbers = new Set(pureNumber);
  if (sameNumbers.size === 1 || errorPatterns.includes(pureNumber.join(""))) {
    return false;
  }

  return true;
}
