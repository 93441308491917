import axios, { AxiosInstance, AxiosPromise } from "axios";
import { SonataResponse } from "../types/SonataResponse";
import { Block } from "../types/Block";
import { Meta } from "../types/Meta";
import { MenuRoute } from "../types/MenuRoute";
import { City } from "../types/City";
import { TeamDepartment } from "../types/TeamDepartment";
import { TopBar } from "../types/TopBar";
import { Slug } from "../types/Slug";
import { FaqResponse } from "../types/Faq";
import { Mentor } from "../types/Mentor";
import { StudentStory } from "../types/StudentStory";
import { ProductPartner } from "../types/ProductPartner";
import { GraduateProject } from "../types/GraduateProject";
import { ContactCourse } from "../types/ContactCourse";
import { ContactDepartment } from "../types/ContactDepartment";
import { ParsedUrlQuery } from "querystring";
import { Article } from "../types/Article";
import { CourseMode } from "../types/CourseMode";
import { DifficultyLevel } from "../types/DifficultyLevel";
import { Product } from "../types/Product";
import moment from "moment";
import { CourseInstance } from "../types/CourseInstance";
import { ProductCategory } from "../types/ProductCategory";
import { FinancingMethod } from "../types/FinancingMethod";
import { ScriptData } from "../types/ScriptData";
import { CompanyData } from "../types/CompanyData";

export default class HttpClient {
  private api: AxiosInstance;

  constructor(locale: string) {
    this.api = axios.create({
      baseURL: process.env.NEXT_PUBLIC_API_BASE,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": locale,
      },
    });
  }

  public getPageBlocks = (
    slug: string
  ): AxiosPromise<SonataResponse<Array<Block>>> =>
    this.api.get(`/api/pages/blocks?url=${slug}`);

  public getPageMeta = (
    slug: string
  ): AxiosPromise<SonataResponse<Array<Meta>>> =>
    this.api.get(`/api/page/meta?url=${slug}`);

  public getMainPageBlocks = (): AxiosPromise<SonataResponse<Array<Block>>> =>
    this.api.get(`/api/main/page/1/blocks`);

  public getMainPageMeta = (): AxiosPromise<SonataResponse<Array<Meta>>> =>
    this.api.get(`/api/main/page/1/meta`);

  public getActiveTopBars = (): AxiosPromise<SonataResponse<Array<TopBar>>> =>
    this.api.get(`/api/top_bars?active=true`);

  public getMenuItems = (): AxiosPromise<SonataResponse<Array<MenuRoute>>> =>
    this.api.get(`/api/menus`);

  public getSlugs = (): AxiosPromise<SonataResponse<Array<Slug>>> =>
    this.api.get(`/api/page/0/slugs`);

  public getCities = (): AxiosPromise<SonataResponse<Array<City>>> =>
    this.api.get(`/api/cities`);

  public getProducts = (): AxiosPromise<SonataResponse<Array<Product>>> =>
    this.api.get(`/api/products`);

  public getFinancingMethods = (): AxiosPromise<
    SonataResponse<Array<FinancingMethod>>
  > => this.api.get(`/api/financing_methods`);

  public getProductsCategories = (): AxiosPromise<
    SonataResponse<Array<ProductCategory>>
  > => this.api.get(`/api/product categories`);

  public getArticle = (
    slug: string
  ): AxiosPromise<SonataResponse<Array<Article>>> =>
    this.api.get(`/api/post/${slug}/details`);

  public getRelatedArticles = (
    slug: string,
    params: ParsedUrlQuery
  ): AxiosPromise<SonataResponse<Array<Article>>> =>
    this.api.get(`/api/posts/${slug}/related`, { params });

  public getCoursesForList = (): AxiosPromise<
    SonataResponse<Array<CourseInstance>>
  > => {
    const currentDate = moment().format("YYYY-MM-DD");

    return this.api.get(
      `/api/courses?pagination=false&order[startAt]=asc&startAt[after]=${currentDate}`
    );
  };

  public getRoute = <T>(route: string): AxiosPromise<T> => this.api.get(route);

  public getModes = (): AxiosPromise<SonataResponse<Array<CourseMode>>> =>
    this.api.get(`/api/course_modes`);

  public getDifficultyLevels = (): AxiosPromise<
    SonataResponse<Array<DifficultyLevel>>
  > => this.api.get(`/api/difficulty levels`);

  public getTeams = (): AxiosPromise<SonataResponse<Array<TeamDepartment>>> =>
    this.api.get(`/api/team_departments`);

  public getContactCourse = (): AxiosPromise<
    SonataResponse<Array<ContactCourse>>
  > => this.api.get(`/api/contact_courses`);

  public getContactDepartments = (): AxiosPromise<
    SonataResponse<Array<ContactDepartment>>
  > => this.api.get(`/api/contact_departments`);

  public getFaq = (id: string): AxiosPromise<FaqResponse> =>
    this.api.get(`/api/faq_sections/${id}`);

  public getPosts = (
    params: ParsedUrlQuery
  ): AxiosPromise<SonataResponse<Array<Article>>> =>
    this.api.get(`/api/posts`, { params });

  public getMentor = (id: string): AxiosPromise<Mentor> =>
    this.api.get(`/api/mentors/${id}`);

  public getProductPartner = (id: string): AxiosPromise<ProductPartner> =>
    this.api.get(`/api/product_partners/${id}`);

  public getGraduateProject = (id: string): AxiosPromise<GraduateProject> =>
    this.api.get(`/api/graduate_projects/${id}`);

  public getStudentStory = (id: string): AxiosPromise<StudentStory> =>
    this.api.get(`/api/student_stories/${id}`);

  public getSiteScript = (
    slug = "index"
  ): AxiosPromise<SonataResponse<Array<ScriptData>>> =>
    this.api.get(`/api/page/${slug}/script`);

  public getCompanyData = (): AxiosPromise<
    SonataResponse<Array<CompanyData>>
  > => this.api.get(`/api/company_datas`);
}

export const getSlugsByLang = (
  lang: string
): AxiosPromise<SonataResponse<Array<Slug>>> => {
  return axios.get(`${process.env.NEXT_PUBLIC_API_BASE}api/page/0/slugs`, {
    headers: { "Accept-Language": lang },
  });
};

export const sendLead = (
  form: Record<string, string | number | boolean | null>
): AxiosPromise =>
  axios.post(`${process.env.NEXT_PUBLIC_API_BASE}api/lead`, form);

export const sendDataForm = (
  data: Record<string, string | number | boolean | null>
): AxiosPromise =>
  axios.post(`${process.env.NEXT_PUBLIC_API_BASE}api/email_send/data`, data);
