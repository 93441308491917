import React, { useEffect, useState } from "react";
import { useTopBarState } from "./TopBarContext";
import { TopBar } from "../../../types/TopBar";
import Link from "next/link";
import { useRouter } from "next/router";
import Marquee from "react-fast-marquee";

const TopBarContainer: React.FunctionComponent<{ topBars: TopBar[] }> = ({
  topBars,
}): React.ReactElement | null => {
  const { pathname } = useRouter();
  const [topBarVisible, setTopBarVisible] = useState(true);
  const [topBarExcludedUrl, setTopBarExcludedUrl] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [topBar, setTopBar] = useState<TopBar | undefined>();
  const { update } = useTopBarState();

  const getData = async (): Promise<void> => {
    try {
      let [topBar] = topBars;
      if (topBar) {
        const excludedUrls = Object.values(topBar.excludedUrls);

        topBar = {
          ...topBar,
          excludedUrls,
        };

        const excludedUrl = excludedUrls.find((url) => url === pathname);
        if (excludedUrl) {
          setTopBarVisible(false);
          update(false);
        }
        setTopBar(topBar);
      }
    } catch {
      setHasError(true);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (topBar) {
      const excludedUrl = topBar.excludedUrls.find((url) => url === pathname);
      setTopBarExcludedUrl(!!excludedUrl);
    }
  }, [pathname]);

  const handleCloseTopBar = (): void => {
    setTopBarVisible(false);
    update(false);
  };

  const TopBarLink = topBar && (
    <Link
      passHref
      href={topBar.url}
      style={{ color: topBar.textColor }}
      className="topBar-link"
    >
      {topBar.message} {" >>"}
    </Link>
  );

  return !hasError && topBar && topBarVisible && !topBarExcludedUrl ? (
    <div className="topBarContainer">
      <Marquee
        pauseOnHover={true}
        speed={50}
        gradient={false}
        className="topBar"
        style={{
          backgroundColor: topBar.backgroundColor,
          color: topBar.textColor,
        }}
      >
        {TopBarLink}
      </Marquee>
      <span className="topBar__closeButton" onClick={handleCloseTopBar}>
        &#x271A;
      </span>
    </div>
  ) : null;
};

export default TopBarContainer;
