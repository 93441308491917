export enum LanguagesEnum {
  AL = "al",
  AT = "at",
  CZ = "cz",
  EN = "en",
  ID = "id",
  PL = "pl",
  RO = "ro",
  RS = "rs",
  SI = "si",
}
