import * as React from "react";
import { PropsWithChildren } from "react";

type BasicButtonProps = PropsWithChildren<{
  onClick: () => void;
  disabled?: boolean;
  className: string;
  type?: "submit" | "button" | "reset";
}>;

const BasicButton: React.FunctionComponent<BasicButtonProps> = ({
  onClick,
  disabled,
  children,
  className,
  type = "button",
}): React.ReactElement => {
  return (
    <button
      type={type}
      className={`BasicButton ${className ? className : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      <span className="BasicButton__label">{children}</span>
    </button>
  );
};

export default BasicButton;
