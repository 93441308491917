import Link from "next/link";
import React, { useRef } from "react";
import { MenuRoute, MenuRouteChild } from "../../../../types/MenuRoute";
import { isFullUrl } from "../../../../utils/validators";

type DesktopMenuItemProps = {
  item: MenuRoute;
  lang: string;
  containerScrollLeft: number | undefined;
};

const DesktopMenuItem: React.FunctionComponent<DesktopMenuItemProps> = ({
  item,
  lang,
  containerScrollLeft,
}): React.ReactElement => {
  const ref = useRef<HTMLLIElement | null>(null);

  const styleForMenu: React.CSSProperties = {};

  if (ref && ref.current) {
    if (containerScrollLeft !== undefined) {
      const left = ref.current.offsetLeft - containerScrollLeft;
      styleForMenu.left = `${left}px`;
    } else {
      styleForMenu.left = `${ref.current.offsetLeft}px`;
    }
  }

  const getSecondLevelMenuChildren = (
    menuItem: MenuRouteChild
  ): React.ReactElement => {
    return (
      <div className="navigationSubMenu__secondLevelContainer">
        <div className="navigationSubMenu__secondLevel">
          <ul>
            {menuItem.children.map((lastLevelItem: MenuRouteChild) => {
              const additionalText = (
                <div className="navigationSubMenu__additionalTextLabel">
                  {lastLevelItem.additionalText && (
                    <span className="navigationSubMenu__additionalTextLabel">
                      {lastLevelItem.additionalText}
                    </span>
                  )}
                  {lastLevelItem.additionalTextWithBg && (
                    <span
                      style={{
                        color: lastLevelItem.additionalTextWbgColor
                          ? lastLevelItem.additionalTextWbgColor
                          : undefined,
                        padding: "1px 10px",
                        backgroundColor:
                          lastLevelItem.additionalTextWbgBackground
                            ? lastLevelItem.additionalTextWbgBackground
                            : undefined,
                      }}
                    >
                      {lastLevelItem.additionalTextWithBg}
                    </span>
                  )}
                </div>
              );

              return (
                <li
                  className={
                    lastLevelItem.menuHeader
                      ? "navigationSubMenu__secondLevelItem navigationSubMenu__secondLevelItem--bold"
                      : "navigationSubMenu__secondLevelItem"
                  }
                  key={lastLevelItem.id}
                >
                  {lastLevelItem.urls ? (
                    isFullUrl(lastLevelItem.urls) ? (
                      <a
                        title={
                          lastLevelItem.seoTitle
                            ? lastLevelItem.seoTitle
                            : undefined
                        }
                        href={lastLevelItem.urls}
                        target={lastLevelItem.target}
                        rel="noopener noreferrer"
                      >
                        <>
                          {lastLevelItem.names}
                          {additionalText}
                        </>
                      </a>
                    ) : (
                      <Link
                        passHref
                        href={`/${lang}${lastLevelItem.urls}`}
                        title={
                          lastLevelItem.seoTitle
                            ? lastLevelItem.seoTitle
                            : undefined
                        }
                        target={lastLevelItem.target}
                      >
                        <>
                          {lastLevelItem.names}
                          {additionalText}
                        </>
                      </Link>
                    )
                  ) : (
                    <span
                      title={
                        lastLevelItem.seoTitle
                          ? lastLevelItem.seoTitle
                          : undefined
                      }
                    >
                      {lastLevelItem.names}
                      {lastLevelItem.additionalText && (
                        <div className="navigationSubMenu__additionalTextLabel">
                          {lastLevelItem.additionalText}
                        </div>
                      )}
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <li key={item.id} ref={ref}>
      {item.urls ? (
        isFullUrl(item.urls) ? (
          <a
            title={item.seoTitle ? item.seoTitle : undefined}
            href={item.urls}
            target={item.target}
            rel="noopener noreferrer"
          >
            {item.names}
          </a>
        ) : (
          <Link
            passHref
            href={`/${lang}${item.urls}`}
            title={item.seoTitle ? item.seoTitle : undefined}
            target={item.target}
          >
            {item.names}
          </Link>
        )
      ) : (
        <span title={item.seoTitle ? item.seoTitle : undefined}>
          {item.names}
        </span>
      )}
      {!!item.children.length && (
        <div className={`navigationSubMenu`} style={styleForMenu}>
          <div className="navigationSubMenu__firstLevel">
            <ul>
              {item.children.map((subMenuItem) => (
                <li
                  className="navigationSubMenu__firstLevelItem"
                  key={subMenuItem.id}
                >
                  {subMenuItem.urls ? (
                    isFullUrl(subMenuItem.urls) ? (
                      <a
                        href={subMenuItem.urls}
                        title={
                          subMenuItem.seoTitle
                            ? subMenuItem.seoTitle
                            : undefined
                        }
                        target={subMenuItem.target}
                        rel="noopener noreferrer"
                        className={
                          subMenuItem.menuHeader
                            ? "navigationSubMenu__firstLevel--bold"
                            : ""
                        }
                      >
                        {subMenuItem.names}
                      </a>
                    ) : (
                      <a
                        href={`/${lang}${subMenuItem.urls}`}
                        title={
                          subMenuItem.seoTitle
                            ? subMenuItem.seoTitle
                            : undefined
                        }
                        className={
                          subMenuItem.menuHeader
                            ? "navigationSubMenu__firstLevel--bold"
                            : ""
                        }
                        target={subMenuItem.target}
                      >
                        {subMenuItem.names}
                      </a>
                    )
                  ) : (
                    <span
                      title={
                        subMenuItem.seoTitle ? subMenuItem.seoTitle : undefined
                      }
                      className={
                        subMenuItem.menuHeader
                          ? "navigationSubMenu__firstLevel--bold"
                          : ""
                      }
                    >
                      {subMenuItem.names}
                    </span>
                  )}

                  {getSecondLevelMenuChildren(subMenuItem)}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </li>
  );
};

export default DesktopMenuItem;
