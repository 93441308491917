import React from "react";

export const arrowIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.669"
    height="13.402"
    viewBox="0 0 27.669 13.402"
  >
    <g transform="translate(27.669 145.402) rotate(180)">
      <g transform="translate(0 132)">
        <path
          className="slider-control-arrow"
          d="M27.352,137.936h0l-5.647-5.62a1.081,1.081,0,0,0-1.525,1.532l3.792,3.773H1.081a1.081,1.081,0,1,0,0,2.162H23.97l-3.792,3.773a1.081,1.081,0,0,0,1.525,1.532l5.647-5.62h0A1.082,1.082,0,0,0,27.352,137.936Z"
          transform="translate(0 -132)"
        />
      </g>
    </g>
  </svg>
);

export const chevronRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.343"
    height="11.404"
    viewBox="0 0 7.343 11.404"
  >
    <g transform="translate(299.973 -1.319) rotate(90)">
      <path
        d="M11.932,299.961a.813.813,0,0,0,.587-1.351l-4.872-5.695a.813.813,0,0,0-1.237,0L1.533,298.61a.813.813,0,1,0,1.235,1.056l4.261-4.974,4.26,4.974a.813.813,0,0,0,.643.3Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

export const magnifierIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.192"
    height="19.192"
    viewBox="0 0 19.192 19.192"
  >
    <g transform="translate(0 0)">
      <path
        d="M2.278,13.29A7.787,7.787,0,0,1,13.291,2.277a7.636,7.636,0,0,1,1.171,9.4.328.328,0,0,0,.045.39l3.973,3.973a1.815,1.815,0,0,1,.278,2.6l-.121.121a1.814,1.814,0,0,1-2.6-.278l-3.965-3.965a.332.332,0,0,0-.4-.054A7.636,7.636,0,0,1,2.278,13.29Zm9.575-1.438a5.754,5.754,0,1,0-8.138,0A5.76,5.76,0,0,0,11.853,11.852Z"
        transform="translate(-0.001 0)"
      />
      <g transform="translate(4.973 3.117)">
        <path
          d="M120.134,54.614a.8.8,0,0,0,.74-1.117,5.065,5.065,0,0,0-6.632-2.688.8.8,0,1,0,.626,1.48,3.456,3.456,0,0,1,4.525,1.834A.8.8,0,0,0,120.134,54.614Z"
          transform="translate(-113.752 -50.409)"
        />
      </g>
    </g>
  </svg>
);

export const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.41 3.42462C11.9958 2.83883 11.9958 1.88909 11.41 1.3033C10.8242 0.717514 9.87446 0.717515 9.28867 1.3033L6.10669 4.48528L2.92471 1.3033C2.33892 0.717514 1.38918 0.717514 0.803389 1.3033C0.217602 1.88909 0.217603 2.83883 0.803389 3.42462L3.98537 6.6066L0.803389 9.78858C0.217602 10.3744 0.217602 11.3241 0.803389 11.9099C1.38918 12.4957 2.33892 12.4957 2.92471 11.9099L6.10669 8.72792L9.28867 11.9099C9.87446 12.4957 10.8242 12.4957 11.41 11.9099C11.9958 11.3241 11.9958 10.3744 11.41 9.78858L8.22801 6.6066L11.41 3.42462Z"
    />
  </svg>
);

export const cartIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.942"
    height="13.068"
    viewBox="0 0 12.942 13.068"
  >
    <path
      d="M30.482,17.475a1.268,1.268,0,0,0-.972-.429h-9.3l-.094-.7a1.42,1.42,0,0,0-1.2-1.349h-.683a.383.383,0,0,0,0,.766h.683c.123,0,.383.26.439.684l1.1,8.219a1.546,1.546,0,0,0,.524.955,1.577,1.577,0,0,0,.734.366,1.408,1.408,0,1,0,2.491.028h2.006a1.393,1.393,0,0,0-.156.642,1.408,1.408,0,1,0,2.816,0,1.393,1.393,0,0,0-.156-.642h1a.383.383,0,1,0,0-.766H21.992a.823.823,0,0,1-.781-.684l-.08-.6H28.69a1.59,1.59,0,0,0,1.54-1.349l.55-4.118A1.271,1.271,0,0,0,30.482,17.475Zm-6.891,9.18a.643.643,0,1,1-.643-.642A.643.643,0,0,1,23.592,26.655Zm4.51,0a.642.642,0,1,1-.643-.642A.643.643,0,0,1,28.1,26.655Zm1.8-8.675a.515.515,0,0,1,.113.415l-.23,1.727h-2.6l.154-2.31H29.51A.515.515,0,0,1,29.907,17.979ZM24.469,23.2l-.154-2.31h2.052l-.154,2.31Zm-.2-3.076-.154-2.31h2.462l-.154,2.31Zm-.922-2.31.154,2.31H20.619l-.308-2.31Zm-2.622,3.076h2.827L23.7,23.2H21.029ZM28.69,23.2H26.98l.154-2.31h2.554l-.216,1.626A.823.823,0,0,1,28.69,23.2Z"
      transform="translate(-17.85 -14.995)"
    />
  </svg>
);

export const filtersIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.381"
    height="25.381"
    viewBox="0 0 25.381 25.381"
  >
    <g transform="translate(0)">
      <path d="M20.84,0H4.541A4.546,4.546,0,0,0,0,4.541v16.3a4.546,4.546,0,0,0,4.541,4.541h16.3a4.546,4.546,0,0,0,4.541-4.541V4.541A4.546,4.546,0,0,0,20.84,0Zm3.053,20.84a3.057,3.057,0,0,1-3.053,3.053H4.541A3.057,3.057,0,0,1,1.487,20.84V4.541A3.057,3.057,0,0,1,4.541,1.487h16.3a3.057,3.057,0,0,1,3.053,3.053Z" />
      <path
        d="M82.533,81.927H71.207a2.336,2.336,0,0,0-4.429,0H65.011a.744.744,0,0,0,0,1.487h1.768a2.336,2.336,0,0,0,4.429,0H82.533a.744.744,0,1,0,0-1.487ZM68.993,83.52a.849.849,0,1,1,.849-.849A.85.85,0,0,1,68.993,83.52Z"
        transform="translate(-61.081 -76.352)"
      />
      <path
        d="M82.533,210.46H80.765a2.336,2.336,0,0,0-4.429,0H65.011a.744.744,0,1,0,0,1.487H76.336a2.336,2.336,0,0,0,4.429,0h1.768a.744.744,0,1,0,0-1.487Zm-3.982,1.593a.849.849,0,1,1,.849-.849A.85.85,0,0,1,78.55,212.053Z"
        transform="translate(-61.081 -198.513)"
      />
      <path
        d="M82.533,338.993H74.393a2.336,2.336,0,0,0-4.429,0H65.011a.744.744,0,0,0,0,1.487h4.954a2.336,2.336,0,0,0,4.429,0h8.139a.744.744,0,0,0,0-1.487Zm-10.354,1.593a.849.849,0,1,1,.849-.849A.85.85,0,0,1,72.179,340.586Z"
        transform="translate(-61.081 -320.674)"
      />
    </g>
  </svg>
);

export const locationIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.766"
    height="15.012"
    viewBox="0 0 11.766 15.012"
  >
    <g transform="translate(-5.811 -3.001)">
      <path d="M16.918,6.166a5.774,5.774,0,0,0-4.568-3.133,6.562,6.562,0,0,0-1.31,0A5.776,5.776,0,0,0,6.471,6.166a6.239,6.239,0,0,0,.855,6.867L11.248,17.8a.577.577,0,0,0,.892,0l3.922-4.769a6.239,6.239,0,0,0,.855-6.867Zm-5.223,4.917A2.31,2.31,0,1,1,14,8.774,2.31,2.31,0,0,1,11.694,11.084Z" />
    </g>
  </svg>
);

export const arrowRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.343"
    height="11.404"
    viewBox="0 0 7.343 11.404"
  >
    <g transform="translate(299.973 -1.319) rotate(90)">
      <path
        d="M11.932,299.961a.813.813,0,0,0,.587-1.351l-4.872-5.695a.813.813,0,0,0-1.237,0L1.533,298.61a.813.813,0,1,0,1.235,1.056l4.261-4.974,4.26,4.974a.813.813,0,0,0,.643.3Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

export const arrowLeft = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.343"
    height="11.404"
    viewBox="0 0 7.343 11.404"
    transform="rotate(180)"
  >
    <g transform="translate(299.973 -1.319) rotate(90)">
      <path
        d="M11.932,299.961a.813.813,0,0,0,.587-1.351l-4.872-5.695a.813.813,0,0,0-1.237,0L1.533,298.61a.813.813,0,1,0,1.235,1.056l4.261-4.974,4.26,4.974a.813.813,0,0,0,.643.3Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);
