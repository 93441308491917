import React from "react";
import MainHeader from "../blocks/MainHeader";
import { MenuRoute } from "../../types/MenuRoute";
import type { ReactElement, FunctionComponent, ReactNode } from "react";
import { useRouter } from "next/router";
import TopBarComponent from "./TopBar";
import { TopBarStateProvider } from "./TopBar/TopBarContext";
import Footer from "./Footer/Footer";
import { TopBar } from "../../types/TopBar";
import { getSlugsByLang } from "../../utils/HttpClient";
import { getSlugsFlatArray, sonataDataGetter } from "../../utils/helpers";
import { Slug } from "../../types/Slug";
import {
  getStaticPathTranslation,
  StaticPathType,
} from "../../utils/StaticPathsTranslations";
import CookiesConfirmation from "./CookiesConfirmation";
import { CompanyData } from "../../types/CompanyData";

type LayoutProps = {
  children: ReactNode;
  routes: MenuRoute[];
  topBars: TopBar[];
  slugs: Slug[];
  companyData?: CompanyData;
  hostUrl?: string;
};

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  routes,
  topBars,
  slugs,
  companyData,
  hostUrl,
}): ReactElement => {
  const { locale, query, push, asPath } = useRouter();
  const socialMedia = companyData?.socialMedia ?? [];

  const changeLanguage = async (lang: string): Promise<void> => {
    const { slug: slugQuery = [] } = query as { slug?: Array<string> };
    let newUrl = "/";

    const staticPath = getStaticPathTranslation(
      asPath,
      locale as keyof StaticPathType,
      lang as keyof StaticPathType
    );

    if (staticPath) {
      newUrl = staticPath;
    } else if (slugQuery.length > 0) {
      const url = `/${slugQuery.join("/")}`;
      const slugsFlatArray = getSlugsFlatArray(slugs);
      const slugObject = slugsFlatArray.find((slug) => slug.name === url);

      if (slugObject) {
        const slugsResponse = await getSlugsByLang(lang);
        const slugsInSecondLanguage = sonataDataGetter(slugsResponse);
        const slugsInSecondLanguageFlatArray = getSlugsFlatArray(
          slugsInSecondLanguage
        );
        const slugInSecondLanguageObject = slugsInSecondLanguageFlatArray.find(
          (slug) => slug.id === slugObject.id
        );

        if (slugInSecondLanguageObject && slugInSecondLanguageObject.name) {
          newUrl = slugInSecondLanguageObject.name;
        }
      }
    }

    push(newUrl, newUrl, { locale: lang });
  };

  const getStructuredData = (): {
    __html: string;
  } => {
    const {
      names,
      pictures: { publicUrl },
      email,
      phone,
      cityName,
      streets,
      numbers,
      zips,
      socialMedia,
    } = companyData as CompanyData;
    const fullPictureUrl = process.env.NEXT_PUBLIC_IMG_URL + publicUrl;

    let data: Record<string, unknown> = {
      "@context": "https://schema.org",
      "@type": "Organization",
      logo: fullPictureUrl,
      name: names,
    };

    if (socialMedia) {
      const socialMediaUrls = socialMedia.map((sm) => sm.link);
      data = { ...data, sameAs: socialMediaUrls };
    }

    if (hostUrl) {
      data = { ...data, url: hostUrl };
    }

    if (email) {
      data = { ...data, email };
    }

    if (phone) {
      data = { ...data, telephone: phone };
    }

    if (cityName && streets && numbers && zips) {
      const address = {
        "@type": "PostalAddress",
        streetAddress: `${streets} ${numbers}`,
        addressLocality: cityName,
        postalCode: zips,
      };
      data = { ...data, address };
    }

    return {
      __html: JSON.stringify(data),
    };
  };

  return (
    <>
      <TopBarStateProvider>
        {companyData && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={getStructuredData()}
            key="product-jsonld"
          />
        )}
        <TopBarComponent topBars={topBars} />
        <MainHeader
          lang={locale as string}
          handleLanguageChange={changeLanguage}
          routes={routes}
        />
        <main>{children}</main>
        <Footer
          lang={locale as string}
          routes={routes}
          socialMedia={socialMedia}
        />
        <CookiesConfirmation />
      </TopBarStateProvider>
    </>
  );
};

export default Layout;
