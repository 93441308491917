export default {
  GREY_BACKGROUND: "grey",
  LANGUAGE: {
    PL: "pl",
    RO: "ro",
    AT: "at",
    ID: "id",
    ES: "es",
    CZ: "cz",
  },
  MENU_POSITIONS: {
    TOP: "top",
    BOTTOM: "bottom",
    MOBILE_BAR: "mobile_bar",
  },
  UNDERNEATH_LAYOUT: "miniature_underneath",
  RES_MOBILE: 568,
  WHITE_BACKGROUND: "white",
};
