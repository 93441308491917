import React from "react";

export const contactIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.995"
    height="13.95"
    viewBox="0 0 15.995 13.95"
  >
    <g transform="translate(-392.063 -305.924)">
      <g transform="translate(395.369 310.442)">
        <path
          d="M397.557,314.174a1.17,1.17,0,1,1,1.17-1.17A1.171,1.171,0,0,1,397.557,314.174Zm0-1.538a.367.367,0,1,0,.367.368A.368.368,0,0,0,397.557,312.636Z"
          transform="translate(-396.387 -311.833)"
        />
        <path
          d="M402.163,314.174a1.17,1.17,0,1,1,1.17-1.17A1.172,1.172,0,0,1,402.163,314.174Zm0-1.538a.367.367,0,1,0,.367.368A.368.368,0,0,0,402.163,312.636Z"
          transform="translate(-397.471 -311.833)"
        />
        <path
          d="M406.768,314.174a1.17,1.17,0,1,1,1.17-1.17A1.171,1.171,0,0,1,406.768,314.174Zm0-1.538a.367.367,0,1,0,.368.368A.367.367,0,0,0,406.768,312.636Z"
          transform="translate(-398.555 -311.833)"
        />
      </g>
      <path d="M403.515,319.874a.4.4,0,0,1-.317-.154l-2.11-2.713h-4.566a4.463,4.463,0,0,1-4.459-4.456V310.38a4.462,4.462,0,0,1,4.459-4.456h7.08a4.461,4.461,0,0,1,4.456,4.456v2.171A4.461,4.461,0,0,1,403.916,317v2.478a.4.4,0,0,1-.4.4Zm-6.993-13.147a3.659,3.659,0,0,0-3.656,3.653v2.171a3.659,3.659,0,0,0,3.656,3.653h4.763a.4.4,0,0,1,.317.154l1.512,1.945v-1.7a.4.4,0,0,1,.4-.4h.087a3.657,3.657,0,0,0,3.653-3.653V310.38a3.657,3.657,0,0,0-3.653-3.653Z" />
    </g>
  </svg>
);
