import staticPathsJson from "./staticPaths.json";
import { LanguagesEnum } from "./LanguagesEnum";

export type StaticPathType = {
  [key in LanguagesEnum]?: string;
};

export const getStaticPathTranslation = (
  slug: string,
  currentLocale: LanguagesEnum,
  nextLocale: LanguagesEnum
): string | null => {
  const staticPaths: Array<StaticPathType> = staticPathsJson;
  const pathObject = staticPaths.find(
    (staticPath) => staticPath[currentLocale] === slug
  );
  return pathObject
    ? pathObject[nextLocale] ?? (pathObject[LanguagesEnum.EN] as string)
    : null;
};
