import React, { Component } from "react";
import constants from "../../../utils/constants";
import DesktopMenu from "./Components/DesktopMenu";
import Media from "react-media";
import MobileMenu from "./Components/MobileMenu";
import { MenuRoute } from "../../../types/MenuRoute";
import { liveChatStyles } from "./utils/style";

type MainHeaderProps = {
  lang: string;
  handleLanguageChange: (lang: string) => void;
  routes: Array<MenuRoute>;
};

type MainHeaderState = {
  scrollBackToTop: boolean;
};

const styleForLiveChat =
  process.env.NEXT_PUBLIC_LIVE_CHAT_SHOW &&
  process.env.NEXT_PUBLIC_LIVE_CHAT_SHOW === "true"
    ? liveChatStyles
    : "";

class MainHeader extends Component<MainHeaderProps, MainHeaderState> {
  state = {
    scrollBackToTop: false,
  };

  componentDidMount(): void {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount(): void {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (): void => {
    this.setState({ scrollBackToTop: window.scrollY > 250 });
  };

  scrollTop = (): void => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  handleLanguageSwitch = (): void => {
    const { lang, handleLanguageChange } = this.props;
    const langToChang =
      lang === process.env.NEXT_PUBLIC_PRIMARY_LANG
        ? process.env.NEXT_PUBLIC_SECONDARY_LANG
        : process.env.NEXT_PUBLIC_PRIMARY_LANG;

    handleLanguageChange(langToChang as string);
  };

  render(): React.ReactElement {
    const { lang, routes } = this.props;
    const { scrollBackToTop } = this.state;

    const menuItems = routes.filter(
      (route) =>
        route.positions.includes(constants.MENU_POSITIONS.TOP) && route.names
    );
    const mobileBarItems = routes.filter(
      (route) =>
        route.positions.includes(constants.MENU_POSITIONS.MOBILE_BAR) &&
        route.names
    );

    return (
      <React.Fragment>
        <header className="main-header">
          <style>{styleForLiveChat}</style>
          <Media query="(min-width: 768px)" defaultMatches={false}>
            <DesktopMenu
              menuItems={menuItems}
              lang={lang}
              handleLanguageSwitch={this.handleLanguageSwitch}
            />
          </Media>

          <img
            src="/blocks/MainHeader/back2top.svg"
            alt="Back to Top"
            onClick={this.scrollTop}
            className={`back-to-top ${
              scrollBackToTop ? "back-to-top--visible" : "back-to-top--hidden"
            }`}
          />
        </header>
        <Media query="(max-width: 767px)" defaultMatches={false}>
          <MobileMenu
            menuItems={menuItems}
            lang={lang}
            handleLanguageSwitch={this.handleLanguageSwitch}
            mobileBarItems={mobileBarItems}
          />
        </Media>
      </React.Fragment>
    );
  }
}

export default MainHeader;
