import React from "react";

import { ICONS } from "./Icons";

type IconsType = typeof ICONS;

type IconProps = {
  icon: keyof IconsType;
  width?: string;
  height?: string;
  color?: string;
  className?: string;
  onClick?: () => void;
};

const Icon: React.FunctionComponent<IconProps> = ({
  onClick,
  icon,
  width = "16",
  height,
  color = "icon-orange",
  className = "",
}): React.ReactElement => {
  const getColor = (): string => {
    switch (color) {
      case "icon-orange":
        return "#fdb933";

      case "dark-grey":
        return "#2a3030";

      default:
        return color;
    }
  };
  const styles = {
    svg: {
      display: "inline-block",
      verticalAlign: "middle",
    },
    path: {
      fill: getColor(),
    },
  };

  return (
    <svg
      className={className}
      onClick={onClick}
      style={styles.svg}
      width={width}
      height={height ? height : width}
      viewBox={`${icon === "RIGHT_ARROW" ? "0 0 2000 1024" : "0 0 1600 1024"}`}
    >
      <path style={styles.path} d={ICONS[icon]} />
    </svg>
  );
};

export default Icon;
