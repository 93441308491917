import React from "react";
import { PictureData } from "../../../types/PictureData";

type PictureProps = {
  data: PictureData;
  className?: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
};

const PictureProvider: React.FunctionComponent<PictureProps> = ({
  data,
  className,
  width,
  height,
  style,
}): React.ReactElement => {
  const fullUrl = process.env.NEXT_PUBLIC_IMG_URL + data.publicUrl;
  return (
    <img
      src={fullUrl}
      alt={data.description}
      title={data.name}
      className={className}
      loading="lazy"
      style={style}
      width={width}
      height={height}
    />
  );
};

export default PictureProvider;
